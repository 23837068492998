import { Component, OnInit, Inject, ViewChild, AfterViewInit, Input, EventEmitter, Output  } from '@angular/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { formatDate } from "@angular/common";

import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';


import { ServicepoolService } from '../services/servicepool.service';

import { ReponseWebService } from '../datastruct/Meloyi.module';

import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';


@Component({
  selector: 'app-detailagence',
  templateUrl: './detailagence.component.html',
  styleUrls: ['./detailagence.component.css']
})
export class DetailagenceComponent implements OnInit {

	@Input() agenceBi : any ;

	allPartners : any ;

	datedeb : any ;
	datefin : any ;

	userchoice = 1 ;

	allServiceSituation: any ;

    totalIn = 0 ; 
    totalOut =  0 ; 
    totalVersement =  0 ;

	samacaabiyoonwii = "" ;

	dataSourceSituations : MatTableDataSource<any> ;

	displayedColumns: string[] = ['Date', 'Service', 'Envoies', 'Retraits', 'Versement' ];

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	retourClientWS  : ReponseWebService ;

	constructor(public dialog: MatDialog,  private configServer: ServicepoolService, private _adapter: DateAdapter<any>, @Inject(MAT_DATE_LOCALE) private _locale: string) {
	    this.datedeb = new Date(); 
	    this.datefin = new Date(); 
	}

	ngOnInit(): void {
	    this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;
	    this.getServiceSituationsPeriode();
	}

	getServiceSituationsPeriode(){

		var debut = formatDate(this.datedeb, "yyyy-MM-dd", 'en-US');
		var fin = formatDate(this.datefin, "yyyy-MM-dd", 'en-US');

		this.configServer.getServiceSituationsPeriode(this.samacaabiyoonwii, debut, fin ).then( (retourbi) => {

		  this.retourClientWS = retourbi ;
		  var statutreq = this.retourClientWS.statutreq;

		  if(statutreq==1){
		    console.log("Done") ;
		    this.allServiceSituation = this.retourClientWS.data ; 
		    this.computeAgenceTotals() ;

	        this.dataSourceSituations =  new MatTableDataSource(this.getAgenceOps()) ;
	        this.dataSourceSituations.paginator = this.paginator;
		    this.dataSourceSituations.sort = this.sort;

		  }
		  else{
		    console.log("Failed") ;
		  }
		}) ;    
	}


	computeAgenceTotals(){
		console.log("Tjerer .... 164") ;
	    this.totalIn = 0 ; 
	    this.totalOut =  0 ; 
	    this.totalVersement =  0 ;
		for ( var j = 0 ; j < this.allServiceSituation.length ; j++) {
			if(this.agenceBi.idpoint == this.allServiceSituation[j].idpoint){
				this.totalIn += Number(this.allServiceSituation[j].totalEnvoie) ;
				this.totalOut += Number(this.allServiceSituation[j].totalRetrait) ;
				this.totalVersement += Number(this.allServiceSituation[j].versement) ;		
			}
		}
	}


	getAgenceOps(){
		console.log("Tjerer .... 147") ;
	    let allAgenceSituations = [] ; 
	    
		for ( var j = 0 ; j < this.allServiceSituation.length ; j++) {
			if(this.agenceBi.idpoint == this.allServiceSituation[j].idpoint){
				allAgenceSituations.push(this.allServiceSituation[j]) ;
			}
		}

		return allAgenceSituations ;
	}




	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSourceSituations.filter = filterValue.trim().toLowerCase();
		this.dataSourceSituations.filter = filterValue.trim().toLowerCase();
	}


	getMontantFormatee(montant){
		let resultd = Number(montant).toLocaleString('fr-FR');
		return resultd ;
	}



}


