import { Component, OnInit, Inject, ViewChild  } from '@angular/core';

import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';

import { ServicepoolService } from '../services/servicepool.service';
import { ReponseWebService } from '../datastruct/Meloyi.module';

@Component({
  selector: 'app-partneroptions',
  templateUrl: './partneroptions.component.html',
  styleUrls: ['./partneroptions.component.css']
})
export class PartneroptionsComponent implements OnInit {

  agenceinfos : any ;

  samacaabiyoonwii = "" ;

  retourClientWS  : ReponseWebService ;

  volet = "main" ;
  voletTampon = "" ;

  operation = "" ;

  montant : any ;
 
  successOp = false ;

  failureOp = false ;

  soldeouverture = 0 ;
  soldefermeture = 0 ;

  activite : any ;
  dateLastConnexion : any ;
  actifDepuis : any ;
  inactifDepuis : any ;

  dataSourceCaisseOps : MatTableDataSource<any> ;

  displayedColumns: string[] = ['datecreat', 'typeoperation', 'montant'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  today : any;


  constructor(private configServer: ServicepoolService, public dialogRef: MatDialogRef<PartneroptionsComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any ) { 
    this.agenceinfos = data.opt ;
    console.log(this.agenceinfos) ;

    this.today = this.getDateBi() ;
  }

  ngOnInit(): void {
      this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;
      this.getLastActivityAgence() ;
      this.getAllCaisseOps() ;
      this.getAllCaisseOpenCloseValues() ;
  }


  annuler(){
  	this.dialogRef.close() ;
  }

  reinitPassWord(){

      this.voletTampon =this.volet ;
      this.volet = "loader" ;

    this.configServer.updateMotDePasseUser(this.samacaabiyoonwii, this.agenceinfos.iduser).then( (retourbi) => {
      this.retourClientWS = retourbi ;
      var statutreq = this.retourClientWS.statutreq;

      this.volet = this.voletTampon;
      console.log(retourbi) ;
      if(statutreq==1){
        console.log("Done Update Password !") ;
        this.successOp = true ;
      }
      else{
        console.log("Failed") ;
        this.failureOp = true ;
      }
    }) ;
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceCaisseOps.filter = filterValue.trim().toLowerCase();
    this.dataSourceCaisseOps.filter = filterValue.trim().toLowerCase();
  }


  desactiverCompte(){

      this.voletTampon =this.volet ;
      this.volet = "loader" ;

      this.configServer.desactiverCompte(this.samacaabiyoonwii, this.agenceinfos.iduser).then( (retourbi) => {
        this.retourClientWS = retourbi ;
        var statutreq = this.retourClientWS.statutreq;

        this.volet = this.voletTampon;
        console.log(retourbi) ;
        if(statutreq==1){
          console.log("Done Update Password !") ;
          this.successOp = true ;
        }
        else{
          console.log("Failed") ;
          this.failureOp = true ;
        }
      }) ;    
  }


  saveOperation(){

      this.voletTampon =this.volet ;
      this.volet = "loader" ;

    this.configServer.updateCaisse(this.samacaabiyoonwii, this.operation, this.montant, this.agenceinfos.idpoint, this.agenceinfos.nompoint).then( (retourbi) => {
      this.retourClientWS = retourbi ;
      var statutreq = this.retourClientWS.statutreq;

      this.volet = this.voletTampon;
      console.log(retourbi) ;
      if(statutreq==1){
        console.log("Done Operation Saved !") ;
        this.successOp = true ;
        this.operation = "" ;
        this.montant = undefined ;
      }
      else{
        console.log("Failed") ;
        this.failureOp = true ;
      }
    }) ;
  }


  getDateBi(){
    let yourDate = new Date() ;
    yourDate.toISOString().split('T')[0] ;
    const offset = yourDate.getTimezoneOffset() ;
    yourDate = new Date(yourDate.getTime() - (offset*60*1000)) ;
    return yourDate.toISOString().split('T')[0] ;
  }


  getLastActivityAgence(){

      this.voletTampon =this.volet ;
      this.volet = "loader" ;

      this.configServer.getLastActivityAgence(this.samacaabiyoonwii, this.agenceinfos.idpoint).then( (retourbi) => {
        this.retourClientWS = retourbi ;
        var statutreq = this.retourClientWS.statutreq;

        this.volet = this.voletTampon;
        console.log(retourbi.data) ;
        if(statutreq==1){
          console.log("Done Last Activity Agence !") ; 
          console.log( this.today) ;
          console.log( retourbi.data.session_day) ;

          let tayla = this.today==retourbi.data.session_day ? 1 : 0 ;

          console.log(tayla) ;
          
          this.activite  = Number(tayla)*Number(retourbi.data.session_state) ;
          this.dateLastConnexion = retourbi.data.session_start;
          this.actifDepuis = retourbi.data.session_start;
          this.inactifDepuis = retourbi.data.session_end;
        }
        else{
          console.log("Failed") ;
        }
      }) ;    
  }

  getAllCaisseOps(){

      this.voletTampon ="main" ;
      this.volet = "loader" ;

      this.configServer.getAllCaisseOps(this.samacaabiyoonwii, this.agenceinfos.idpoint).then( (retourbi) => {
        this.retourClientWS = retourbi ;
        var statutreq = this.retourClientWS.statutreq;

        this.volet = this.voletTampon;
        console.log(retourbi.data) ;
        if(statutreq==1){
          console.log("Done Update Password !") ;
          this.dataSourceCaisseOps =  new MatTableDataSource(retourbi.data) ;
          this.dataSourceCaisseOps.paginator = this.paginator;
          this.dataSourceCaisseOps.sort = this.sort;
        }
        else{
          console.log("Failed") ;
        }
      }) ;    
  }


  getAllCaisseOpenCloseValues(){
      this.voletTampon ="main" ;
      this.volet = "loader" ;
      this.configServer.getAllCaisseOpenCloseValues(this.samacaabiyoonwii, this.agenceinfos.idpoint).then( (retourbi) => {
        this.retourClientWS = retourbi ;
        var statutreq = this.retourClientWS.statutreq;

        this.volet = this.voletTampon;
        console.log(retourbi.data) ;
        if(statutreq==1){
          console.log("Done Get Caisses !") ;
          this.soldeouverture = retourbi.data.solde_ouvert ;
          this.soldefermeture = retourbi.data.solde_fermet;
        }
        else{
          console.log("Failed") ;
        }
      }) ;    
  }

  getMontantFormatee(montant){

    let resultd = Number(montant).toLocaleString('fr-FR');

    if (resultd=="NaN")
      resultd = "0" ;

    return resultd ;
  }


}
