import { Routes } from '@angular/router';

import { DashboardComponent } from '../../dashboard/dashboard.component';
import { UserProfileComponent } from '../../user-profile/user-profile.component';
import { TableListComponent } from '../../table-list/table-list.component';
import { TypographyComponent } from '../../typography/typography.component';
import { IconsComponent } from '../../icons/icons.component';
import { MapsComponent } from '../../maps/maps.component';
import { NotificationsComponent } from '../../notifications/notifications.component';
import { UpgradeComponent } from '../../upgrade/upgrade.component';
import { AuthentificationComponent } from '../../authentification/authentification.component';
import { DeliverymanagerComponent } from '../../deliverymanager/deliverymanager.component';

import { CommandesComponent } from '../../commandes/commandes.component';
import { LivraisonComponent } from '../../livraison/livraison.component';
import { PartenairesComponent } from '../../partenaires/partenaires.component';
import { FinancesComponent } from '../../finances/finances.component';

import { BulletinsalaireComponent } from '../../bulletinsalaire/bulletinsalaire.component';

import { TransactsComponent } from '../../transacts/transacts.component';

export const AdminLayoutRoutes: Routes = [

    { path: 'dashboard',      component: DashboardComponent },
    { path: 'partners',   component: PartenairesComponent },
    { path: 'delivery',     component: LivraisonComponent },
    { path: 'finances',     component: FinancesComponent },
    { path: 'documents',     component: BulletinsalaireComponent },
    { path: 'histotransact',     component: TransactsComponent }

];
