import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';

import { ServicepoolService } from '../services/servicepool.service';
import { ReponseWebService } from '../datastruct/Meloyi.module';


@Component({
  selector: 'app-makebank',
  templateUrl: './makebank.component.html',
  styleUrls: ['./makebank.component.css']
})

export class MakebankComponent implements OnInit {

  prenomUser = "" ;
  nomUser = "" ;
  telephoneUser = "" ;
  permisUser = "" ;
  loginUser = "" ;

  regionPoint = "" ;

  idpoint = "" ;


  adressepoint = "" ;

  samacaabiyoonwii = "" ; 
 
  nomBank = "" ;
  sigle = "---" ;

  choosenPoint : any ; 

  allCompagnies : any ;

  allRegions = ["Dakar", "Diourbel", "Fatick", "Kaolack", "Kolda", "Louga", "Matam", 
                    "St Louis", "Tambacounda", "Thiès", "Ziguinchor", "Kaffrine", "Kédougou", "Sédhiou"] ;

  useral : any ;

  doneCreating = 0 ;

  retourClientWS  : ReponseWebService ;


  constructor(private configServer: ServicepoolService, public dialogRef: MatDialogRef<MakebankComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any ) {
  	this.useral = data.opt ;
    this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;
    this.getAllCompanies() ;    
  }


  ngOnInit(): void {
  }



  creerBank(): void {
    if( this.nomBank.length>=2 ){

      this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;

      this.configServer.createBank(this.samacaabiyoonwii, this.nomBank ).then( (retourbi) => {
        this.retourClientWS = retourbi ;
        var statutreq = this.retourClientWS.statutreq ;
        if(statutreq==1){ 
          console.log("Done. Bank created !") ;
          this.doneCreating = 1 ;
        }
        else
          console.log("Failed") ;
      }) ;          
    }    
  }


  choosedInSelect(choosenCompanieName){
    this.nomBank = choosenCompanieName ;
  }

  choosedInSelectRegion(regionNameEvent){
    this.regionPoint = regionNameEvent.value;
  }


  choosedInSelectPoint(pointIndexEvent){
    this.choosenPoint = this.allCompagnies[pointIndexEvent.value]  ;
  }


  creerAdminAndCompany(): void {

    if(this.nomBank.length>=2 && this.regionPoint.length>=2 &&  this.adressepoint.length>=2 ){
      this.configServer.createCompanyAndAdmin(1, this.nomBank, this.regionPoint, this.adressepoint).then( (retourbi) => {
        this.retourClientWS = retourbi ;
        var statutrec = this.retourClientWS.statutreq ;
        if(statutrec==1){
          console.log("Done") ;
          this.doneCreating = 1 ;
        }
        else
          console.log("Failed") ;
      }) ;          
    }
  }


  getAllCompanies(){

    this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;

    this.configServer.getAllCompanies(this.samacaabiyoonwii ).then( (retourbi) => {
      this.retourClientWS = retourbi ;
      var statutreq = this.retourClientWS.statutreq;

      if(statutreq==1){
        this.allCompagnies = this.retourClientWS.data ; 
      }
      else{
        console.log("Failed") ;
      }
    }) ;    
  }


  annuler(){
  	this.dialogRef.close() ;
  }


}
