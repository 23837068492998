import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as Chartist from 'chartist';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { interval } from 'rxjs';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';


const source = interval(120000);

const httpOptions = {
  headers: new HttpHeaders(
    {
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  )
};

const httpOptionsII = {
  headers: new HttpHeaders(
    {
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  )
};




@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  handleError : any ;
   commands : any[] ;
   commandsLastWeek : any[] ;
   commandsCurrentWeek : any[] ;
   nbreCmds = 0 ;
   revenus = 0 ;
   panierMoyen = 0 ;
   totalLivraison = 0 ;
   subscription : any ;

   longTotal = 0 ; 
   redLine = 0 ;
   OrangeLine = 0 ;
   blueLine = 0 ;
   yellowLine = 0 ;
   greenLine = 0 ;


  presences = 0 ;
  assigned = 0 ;
  retards = 0 ; 
  running = 0 ;
  absences = 0 ;


  ourFirstday  : any ;
  firstDayOfLastWeek : any ;
  lastDayOfLastWeek : any ;

  firstDayOfMonth : any ;

  today = new Date() ;


    weekday = [];


  @ViewChild('myDiv') myDiv: ElementRef ;

  constructor(private httpClient: HttpClient) { 

      this.weekday[0] =  "Dimanche";
      this.weekday[1] = "Lundi";
      this.weekday[2] = "Mardi";
      this.weekday[3] = "Mercredi";
      this.weekday[4] = "Jeudi";
      this.weekday[5] = "Vendredi";
      this.weekday[6] = "Samedi";
 
      var currentDate = new Date();

      if(currentDate.getDay()!= 0){ 
        var trueFirstday = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()) );
        this.ourFirstday = new Date(currentDate.setDate(trueFirstday.getDate() + 1 ) );
      }else{
        this.ourFirstday = new Date(currentDate.setDate(currentDate.getDate() - 6 ) );        
      }

      this.firstDayOfLastWeek = new Date(currentDate.setDate(this.ourFirstday.getDate() -  7)) ;
      this.lastDayOfLastWeek = new Date(currentDate.setDate(this.firstDayOfLastWeek.getDate() + 6)) ;  

      var dateD = new Date(), y = dateD.getFullYear(), m = dateD.getMonth();
      this.firstDayOfMonth = new Date(y, m, 1);

      this.ourFirstday = this.ourFirstday.toISOString().slice(0, 10) ;
      this.firstDayOfLastWeek = this.firstDayOfLastWeek.toISOString().slice(0, 10) ;
      this.lastDayOfLastWeek = this.lastDayOfLastWeek.toISOString().slice(0, 10) ;
      this.firstDayOfMonth = this.firstDayOfMonth.toISOString().slice(0, 10) ;

      console.log("DAYS") ;
      console.log(this.ourFirstday, this.firstDayOfLastWeek, this.lastDayOfLastWeek, this.firstDayOfMonth) ;

  }



  startAnimationForLineChart(chart){
      let seq: any, delays: any, durations: any;
      seq = 0;
      delays = 80;
      durations = 500;

      chart.on('draw', function(data) {
        if(data.type === 'line' || data.type === 'area') {
          data.element.animate({
            d: {
              begin: 600,
              dur: 700,
              from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
              to: data.path.clone().stringify(),
              easing: Chartist.Svg.Easing.easeOutQuint
            }
          });
        } else if(data.type === 'point') {
              seq++;
              data.element.animate({
                opacity: {
                  begin: seq * delays,
                  dur: durations,
                  from: 0,
                  to: 1,
                  easing: 'ease'
                }
              });
          }
      });

      seq = 0;
  };
  startAnimationForBarChart(chart){
      let seq2: any, delays2: any, durations2: any;

      seq2 = 0;
      delays2 = 80;
      durations2 = 500;
      chart.on('draw', function(data) {
        if(data.type === 'bar'){
            seq2++;
            data.element.animate({
              opacity: {
                begin: seq2 * delays2,
                dur: durations2,
                from: 0,
                to: 1,
                easing: 'ease'
              }
            });
        }
      });

      seq2 = 0;
  };


  ngOnInit() {
    this.opensnack() ;
    this.dataOnWeekBases();
    this.subscription = source.subscribe(val => this.opensnack());
    this.subscription = source.subscribe(val => this.dataOnWeekBases());
  }


  public dataOnWeekBases(){
      this.remoteDataByInterv('http://144.91.121.43/lis/getcommandesdashboardInterval.php', {'deb' : this.firstDayOfLastWeek, 'fin' : this.lastDayOfLastWeek}).subscribe({
            next: retourPost => {

              this.commandsLastWeek = retourPost ;


              console.log(this.commandsLastWeek);
              console.log("lastweek") ;
        this.remoteDataByInterv('http://144.91.121.43/lis/getcommandesweekbi.php', {'deb' : this.ourFirstday}).subscribe({
              next: retourPost => {
                this.commandsCurrentWeek = retourPost ;

              console.log("thisweek") ;

              console.log(this.commandsCurrentWeek);

                this.weekday[0] =  "Dimanche";
                this.weekday[1] = "Lundi";
                this.weekday[2] = "Mardi";
                this.weekday[3] = "Mercredi";
                this.weekday[4] = "Jeudi";
                this.weekday[5] = "Vendredi";
                this.weekday[6] = "Samedi";

                let totalLundi = this.totalByDay(this.commandsLastWeek, 1) ;
                let totalMardi = this.totalByDay(this.commandsLastWeek, 2) ;
                let totalMercredi = this.totalByDay(this.commandsLastWeek, 3) ;
                let totalJeudi = this.totalByDay(this.commandsLastWeek, 4) ;
                let totalVendredi = this.totalByDay(this.commandsLastWeek, 5) ;
                let totalSamedi = this.totalByDay(this.commandsLastWeek, 6) ;
                let totalDimanche = this.totalByDay(this.commandsLastWeek, 0) ;

                let highestValuePasse = Math.max(totalLundi, totalMardi, totalMercredi, totalJeudi, totalVendredi, totalSamedi, totalDimanche) ;

                let totalLundiBii = this.totalByDay(this.commandsCurrentWeek, 1) ;
                let totalMardiBii = this.totalByDay(this.commandsCurrentWeek, 2) ;
                let totalMercrediBii = this.totalByDay(this.commandsCurrentWeek, 3) ;
                let totalJeudiBii = this.totalByDay(this.commandsCurrentWeek, 4) ;
                let totalVendrediBii = this.totalByDay(this.commandsCurrentWeek, 5) ;
                let totalSamediBii = this.totalByDay(this.commandsCurrentWeek, 6) ;
                let totalDimancheBii = this.totalByDay(this.commandsCurrentWeek, 0) ;

                let highestValueWkBii = Math.max(totalLundiBii, totalMardiBii, totalMercrediBii, totalJeudiBii, totalVendrediBii, totalSamediBii, totalDimancheBii) ;

                let highestValue = Math.max(highestValuePasse, highestValueWkBii) ;

                var data = {
                  series: [5, 3, 4]
                };

                var sum = function(a, b) { return a + b };

                var dailyFlowChart = new Chartist.Pie('.ct-chart', data, {
                  labelInterpolationFnc: function(value) {
                    return Math.round(value / data.series.reduce(sum) * 100) + '%';
                  }
                });

                const dataDailySalesChart: any = {
                    labels: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
                    series: [
                        [totalLundi, totalMardi, totalMercredi, totalJeudi, totalVendredi, totalSamedi, totalDimanche],
                        [totalLundiBii, totalMardiBii, totalMercrediBii, totalJeudiBii, totalVendrediBii, totalSamediBii, totalDimancheBii]
                    ]
                };

                const optionsDailySalesChart: any = {
                    lineSmooth: Chartist.Interpolation.cardinal({
                        tension: 0
                    }),
                    low: 0,
                    high: highestValue+10, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                    chartPadding: { top: 0, right: 0, bottom: 0, left: 0},
                }



                var dailySalesChart = new Chartist.Line('#dailySalesChart', dataDailySalesChart, optionsDailySalesChart);

                this.startAnimationForLineChart(dailySalesChart);


              },
              error: err => {console.log(err)},
              complete: () => console.log('DONE!')
        }) ;    
      },
      error: err => {console.log(err)},
      complete: () => console.log('DONE!')
    }) ;    
  }


  public opensnack() : void {
      this.remotedata('http://144.91.121.43/lis/getcommandesdashboard.php', {}).subscribe({
            next: retourPost => {
              this.commands = retourPost ;

              this.nbreCmds = this.commands.length ;
              this.revenus = this.totalCmds(this.commands) ;
              this.panierMoyen = this.calculPanierMoyen(this.commands) ;
              this.totalLivraison = this.calculNbreLivraison(this.commands) ;


              this.presences = this.totalCmdsByState(this.commands, 1) ;
              this.retards = this.totalCmdsByState(this.commands, -1) ;
              this.absences = this.totalCmdsByState(this.commands, 0) ;


            const dataCompletedTasksChart: any = {
                labels: ['12p', '3p', '6p', '9p', '12p', '3a', '6a', '9a'],
                series: [
                    [230, 750, 450, 300, 280, 240, 200, 190]
                ]
            };

           const optionsCompletedTasksChart: any = {
                lineSmooth: Chartist.Interpolation.cardinal({
                    tension: 0
                }),
                low: 0,
                high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
                chartPadding: { top: 0, right: 0, bottom: 0, left: 0}
            }

            var completedTasksChart = new Chartist.Line('#completedTasksChart', dataCompletedTasksChart, optionsCompletedTasksChart);

            // start animation for the Completed Tasks Chart - Line Chart
            this.startAnimationForLineChart(completedTasksChart);

            var datawebsiteViewsChart = {
              labels: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
              series: [
                [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]

              ]
            };
            var optionswebsiteViewsChart = {
                axisX: {
                    showGrid: false
                },
                low: 0,
                high: 1000,
                chartPadding: { top: 0, right: 5, bottom: 0, left: 0}
            };
            var responsiveOptions: any[] = [
              ['screen and (max-width: 640px)', {
                seriesBarDistance: 5,
                axisX: {
                  labelInterpolationFnc: function (value) {
                    return value[0];
                  }
                }
              }]
            ];

            var websiteViewsChart = new Chartist.Bar('#websiteViewsChart', datawebsiteViewsChart, optionswebsiteViewsChart, responsiveOptions);

              //start animation for the Emails Subscription Chart
            this.startAnimationForBarChart(websiteViewsChart);


            this.longTotal =  (this.myDiv.nativeElement as HTMLElement).offsetWidth - 100 ;

            let allLineNum =  this.presences + this.retards + this.absences;

            this.redLine = this.absences * this.longTotal / allLineNum;
            this.blueLine = this.retards * this.longTotal / allLineNum ;
            this.greenLine = this.presences * this.longTotal / allLineNum ;

            console.log(this.redLine) ;
            console.log(this.blueLine) ;
            console.log(this.greenLine) ;

            },
            error: err => {console.log(err)},
            complete: () => console.log('DONE!')
      }) ;    
  }

  remotedata(url: string, user: any) {
      return this.httpClient.post<any[]>(url, user, httpOptions).pipe(catchError(this.handleError)) ;      
  }

  remoteDataByInterv(url: string, user: any) {
    return this.httpClient.post<any[]>(url, user, httpOptionsII);
  }

  totalCmds(ListCommands){
    let  total : number = 0 ;
    for (var i = ListCommands.length - 1; i >= 0; i--) {
      total = total + parseInt( ListCommands[i].montant) ;
     }
     return total ;
  }


  totalByDay(ListCommands, dayOrder){

    let total : number = 0 ;

    for (var i = ListCommands.length - 1; i >= 0; i--) {
      var theDayI = new Date(ListCommands[i].datejour);
      var day = theDayI.getDay();      
       if( day == dayOrder )
        total = total + 1;
     }

     console.log(total) ;
     return total ;    
  }



  totalCmdsByState(ListCommands, etat){
    let  total : number = 0 ;
    if(etat!=-1){
      for (var i = ListCommands.length - 1; i >= 0; i--) {
         if( parseInt( ListCommands[i].presence)==etat )
          total = total + 1 ;
       }      
    }else{
      for (var i = ListCommands.length - 1; i >= 0; i--) {
         if( parseInt( ListCommands[i].presence)==1 &&  parseInt( ListCommands[i].retard)==1 )
          total = total + 1 ;
       }            
    }

     console.log(etat.toString()+" - - - "+total.toString() ) ;
     return total ;    
  }



  calculPanierMoyen(commandsList){
    return Math.trunc( this.totalCmds(commandsList) / commandsList.length ) ;
  }

  calculNbreLivraison(ListCommands){
    let  total : number = 0 ;
    for (var i = ListCommands.length - 1; i >= 0; i--) {
       if( parseInt( ListCommands[i].etat)==5 )
        total = total + 1 ;
     }
     return total ;    
  }


}


 @Pipe({
   name: 'customDate'
 })
 export class CustomDatePipe extends 
              DatePipe implements PipeTransform {
   transform(value: any, args?: any): any {
     return super.transform(value, "EEEE d MMMM y h:mm a");
   }
 }

