import { Component, OnInit, Inject, ViewChild, AfterViewInit, Input, EventEmitter, Output } from '@angular/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { formatDate } from "@angular/common";

import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';


import { ServicepoolService } from '../services/servicepool.service';

import { ReponseWebService } from '../datastruct/Meloyi.module';

import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

import { MakebankComponent } from '../makebank/makebank.component';

import { MakeserviceComponent } from '../makeservice/makeservice.component';

import { UserplanningComponent } from '../userplanning/userplanning.component';

import { UseroptionsComponent } from '../useroptions/useroptions.component';

import { PointoptionsComponent } from '../pointoptions/pointoptions.component';

import { DashboardComponent } from '../dashboard/dashboard.component';

@Component({
  selector: 'app-finances',
  templateUrl: './finances.component.html',
  styleUrls: ['./finances.component.css']
})
export class FinancesComponent implements OnInit {

	commands = [] ;

	allTheBanks : any ;
	allTheServices : any ;
	allTheAgences  : any ;

	allServiceSituation : any ;


	datedeb : any ;
	datefin : any ;

	displayedColumnsPoint: string[] = ['nompoint', 'region', 'adresse',  'created_at', 'details'];
	displayedColumns: string[] = ['prenom', 'nom', 'telephone', 'point', 'adresse', 'region', 'created_at', 'details'];

	dataSource : any ;

	dataSourceBanks : any ;

	dataSourceActifPoints : any ;
	dataSourceInactifPoints : any ;

	dataSourceServiceSituation : any ;
	dataSourceInactifUsers : any ;


	choosenGroup = "Banques" ;


	nbreActifs = 0 ;
	nbreInactifs = 0 ;

	nbreActifUsers = 0 ;
	nbreInactifUsers = 0;


	versementBanks :any = []; 
	
	versementServices:any = []; 

	versementAgences: any = [] ;

	userchoice = 1 ;

	filtre = "" ;

	samacaabiyoonwii = "" ;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	retourClientWS  : ReponseWebService ;

	constructor(public dialog: MatDialog,  private configServer: ServicepoolService, private _adapter: DateAdapter<any>, @Inject(MAT_DATE_LOCALE) private _locale: string) {
	    this._locale = 'fr';
	    this._adapter.setLocale(this._locale);
/*	    this.datedeb = new Date(); 
	    this.datefin = new Date(); 		
*/	
	}
	

	ngOnInit(): void {
	    this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;
	    this.userchoice = 1 ;
	    this.loadInfos(this.userchoice) ;
		this.getAllServices();
	}

	
    getProductionByDateInterval(){

	    var debut = formatDate(this.datedeb, "yyyy-MM-dd", 'en-US');
	    var fin = formatDate(this.datefin, "yyyy-MM-dd", 'en-US');

	    this.configServer.getGlobalProductionByDateInterval(1, debut, fin).then( (retourbi) => {
	      this.retourClientWS = retourbi ;
	      var statutrec = this.retourClientWS.statutreq ;

	      if(statutrec==1){
	        console.log("Done") ;
	        this.allTheBanks = this.retourClientWS.data ; 
	        this.dataSource =  new MatTableDataSource(this.allTheBanks) ;
	        this.dataSource.paginator = this.paginator;
		    this.dataSource.sort = this.sort;
	      }
	      else
	        console.log("Failed") ;
	    }) ;    
	}

	
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
		this.dataSourceBanks.filter = filterValue.trim().toLowerCase();
	}


	loadInfos(inside){

		this.userchoice = inside ;

		if(inside==1){
			this.getAllBanques();
			this.choosenGroup = "Banques" ;
		}
		if(inside==2){
			this.getAllServices();
			this.choosenGroup = "Services" ;
		}
		if(inside==3){
			this.getAllAgences();
			this.choosenGroup = "Points / Agences" ;
		}

	}



	computeBankVersements(){
		this.versementBanks = [] ;
		console.log("Tjerer .... 147") ;
		for ( var i = 0 ; i < this.allTheBanks.length; i++ ) {
			this.versementBanks.push({totalIn: 0, totalOut: 0, totalVersement: 0}) ;
			for ( var j = 0 ; j < this.allServiceSituation.length ; j++) {
				if(this.allTheBanks[i].idBank == this.allServiceSituation[j].idBankPart){
					this.versementBanks[i].totalIn += Number(this.allServiceSituation[j].totalEnvoie) ;
					this.versementBanks[i].totalOut += Number(this.allServiceSituation[j].totalRetrait) ;
					this.versementBanks[i].totalVersement += Number(this.allServiceSituation[j].versement) ;		
				}
			}
		}
	}


	computeServiceVersements(){
		this.versementServices = [] ;
		console.log("Tjerer .... 164") ;
		for ( var i = 0 ; i < this.allTheServices.length; i++ ) {
			this.versementServices.push({totalIn: 0, totalOut: 0, totalVersement: 0}) ;
			for ( var j = 0 ; j < this.allServiceSituation.length ; j++) {
				if(this.allTheServices[i].idService == this.allServiceSituation[j].idService){
					this.versementServices[i].totalIn += Number(this.allServiceSituation[j].totalEnvoie) ;
					this.versementServices[i].totalOut += Number(this.allServiceSituation[j].totalRetrait) ;
					this.versementServices[i].totalVersement += Number(this.allServiceSituation[j].versement) ;		
				}
			}
		}
	}



	computeAgenceVersements(){
		this.versementAgences = [] ;
		console.log("Tjerer .... 164") ;
		for ( var i = 0 ; i < this.allTheAgences.length; i++ ) {
			this.versementAgences.push({totalIn: 0, totalOut: 0, totalVersement: 0}) ;
			for ( var j = 0 ; j < this.allServiceSituation.length ; j++) {
				if(this.allTheAgences[i].idpoint == this.allServiceSituation[j].idpoint){
					this.versementAgences[i].totalIn += Number(this.allServiceSituation[j].totalEnvoie) ;
					this.versementAgences[i].totalOut += Number(this.allServiceSituation[j].totalRetrait) ;
					this.versementAgences[i].totalVersement += Number(this.allServiceSituation[j].versement) ;		
				}
			}
		}
	}



   getAllBanques(){
    this.configServer.getAllBanks(this.samacaabiyoonwii).then( (retourbi) => {
      this.retourClientWS = retourbi ;
      var statutreq = this.retourClientWS.statutreq;

      if(statutreq==1){
        console.log("Done 169") ;
        this.allTheBanks = this.retourClientWS.data ; 
		this.getServiceSituations() ;
      }
      else{
        console.log("Failed") ;
      }
    }) ;
   }


   getLogo(logoName){
   	return "./assets/img/"+logoName+".png" ;
   }

	getAllServices(){

		this.configServer.getAllServices(this.samacaabiyoonwii).then( (retourbi) => {
		  this.retourClientWS = retourbi ;
		  var statutreq = this.retourClientWS.statutreq;

		  if(statutreq==1){
		    console.log("Done") ;
		    this.allTheServices = this.retourClientWS.data ; 
		    this.getServiceSituations() ;
		  }
		  else{
		    console.log("Failed") ;
		  }
		}) ;    
	}


	getAllAgences(){

		this.configServer.getAllCompanies(this.samacaabiyoonwii).then( (retourbi) => {
		  this.retourClientWS = retourbi ;
		  var statutreq = this.retourClientWS.statutreq;

		  if(statutreq==1){
		    console.log("Done") ;
		    this.allTheAgences = this.retourClientWS.data ; 
			this.getServiceSituations() ;
		  }
		  else{
		    console.log("Failed") ;
		  }
		}) ;    
	}


	getAllActifsPoints(){
		this.configServer.getAllActifsPoints(this.samacaabiyoonwii).then( (retourbi) => {
		  this.retourClientWS = retourbi ;
		  var statutreq = this.retourClientWS.statutreq;

		  if(statutreq==1){
		    console.log("Done") ;
		    this.allTheBanks = this.retourClientWS.data ; 

			this.nbreActifs = this.allTheBanks.length ;

		    this.dataSourceActifPoints =  new MatTableDataSource(this.allTheBanks) ;
		    this.dataSourceActifPoints.paginator = this.paginator;
		    this.dataSourceActifPoints.sort = this.sort;
		  }
		  else{
		    console.log("Failed") ;
		  }
		}) ;
	}


	getAllInactifsPoints(){
		this.configServer.getAllInactifsPoints(this.samacaabiyoonwii).then( (retourbi) => {
		  this.retourClientWS = retourbi ;
		  var statutreq = this.retourClientWS.statutreq;

		  if(statutreq==1){
		    console.log("Done") ;
		    this.allTheBanks = this.retourClientWS.data ; 

			this.nbreInactifs = this.allTheBanks.length ;

		    this.dataSourceInactifPoints =  new MatTableDataSource(this.allTheBanks) ;
		    this.dataSourceInactifPoints.paginator = this.paginator;
		    this.dataSourceInactifPoints.sort = this.sort;
		  }
		  else{
		    console.log("Failed") ;
		  }
		}) ;    
	}

   	isLike(Currentvalue: string){
    	return Currentvalue.toLowerCase().includes(this.filtre.toLowerCase() ) ;
	}


	getServiceSituations(){

		console.log(" getServiceSituations 245") ;

		this.configServer.getServiceSituations(this.samacaabiyoonwii).then( (retourbi) => {
		  this.retourClientWS = retourbi ;
		  var statutreq = this.retourClientWS.statutreq;

		  if(statutreq==1){
		    console.log("Done 249") ;
		    this.allServiceSituation = this.retourClientWS.data ; 
            this.computeBankVersements() ;
            this.computeServiceVersements() ;
            this.computeAgenceVersements() ;
		  }
		  else{
		    console.log("Failed") ;
		  }
		}) ;
	}


	getMontantFormatee(montant){
		let resultd = Number(montant).toLocaleString('fr-FR');
		return resultd ;
	}


	getAllInactifsUsers(){
		this.configServer.getAllInactifsUsers(this.samacaabiyoonwii).then( (retourbi) => {
		  this.retourClientWS = retourbi ;
		  var statutreq = this.retourClientWS.statutreq;

		  if(statutreq==1){
		    console.log("Done") ;
		    this.allTheBanks = this.retourClientWS.data ; 

			this.nbreInactifUsers = this.allTheBanks.length ;

		    this.dataSourceInactifUsers =  new MatTableDataSource(this.allTheBanks) ;
		    this.dataSourceInactifUsers.paginator = this.paginator;
		    this.dataSourceInactifUsers.sort = this.sort;
		  }
		  else{
		    console.log("Failed") ;
		  }
		}) ;    
	}


	openDialogBankMaker(cmd): void {		
		let passedValues = {'opt':cmd} ;
		const dialogRef = this.dialog.open(MakebankComponent, {
		  data: passedValues, disableClose:true
		});
		dialogRef.afterClosed().subscribe(result => {
		  console.log('The dialog was closed');
		});		
	}


	openDialogServiceMaker(cmd): void {		
		let passedValues = {'opt':cmd} ;
		const dialogRef = this.dialog.open(MakeserviceComponent, {
		  data: passedValues, disableClose:true
		});
		dialogRef.afterClosed().subscribe(result => {
		  console.log('The dialog was closed');
		});		
	}


	openDialogUser(elt): void {		
		let passedValues = {'opt':elt} ;
		const dialogRef = this.dialog.open(UseroptionsComponent, {
		  data: passedValues, disableClose:true
		});

		dialogRef.afterClosed().subscribe(result => {
		  console.log('The dialog was closed');
		});		
	}

	openDialogPar(elt): void {		
		let passedValues = {'opt':elt} ;
		const dialogRef = this.dialog.open(UserplanningComponent, {
		  data: passedValues, disableClose:true
		});

		dialogRef.afterClosed().subscribe(result => {
		  console.log('The dialog was closed');
		});		
	}


}

