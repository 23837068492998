import { Component, OnInit, Inject, Input, Output, ViewChild, AfterViewInit} from '@angular/core';

import { HttpClient, HttpHeaders, HttpResponse, HttpEventType } from '@angular/common/http';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { formatDate } from "@angular/common";

import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';


import { DetailcommandeComponent } from '../detailcommande/detailcommande.component';
import {Observable} from 'rxjs';

import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import { ServicepoolService } from '../services/servicepool.service';

import { ReponseWebService } from '../datastruct/Meloyi.module';

import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

import { ExportationComponent } from '../exportation/exportation.component';


import * as XLSX from 'xlsx';

@Component({
  selector: 'app-commandes',
  templateUrl: './commandes.component.html',
  styleUrls: ['./commandes.component.css'],
})
export class CommandesComponent implements OnInit {

  commands = [] ;

  allProduction : any ;

  datedeb : any ;
  datefin : any ;

  courses = 0 ;

  theFiltre = '#' ;

  peutavoirdescente = false; 


  fileName= 'ExcelSheet.xlsx';

  listedchoice = "********" ;
 
  displayedColumns: string[] = ['date', 'entreprise', 'hpointe', 'hemarge', 'hdescente', 'prenom', 'nom', 'telephone'];
  dataSource : any ;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  retourClientWS  : any ;
  

  constructor(public dialog: MatDialog,  private configServer: ServicepoolService, private _adapter: DateAdapter<any>, @Inject(MAT_DATE_LOCALE) private _locale: string) { 
    this._locale = 'fr';
    this._adapter.setLocale(this._locale);
    this.datedeb = new Date(); 
    this.datefin = new Date(); 
  }

	ngOnInit(): void {
    this.getAllPresences();
	}

  ngAfterViewInit() {    
  }

  handleDateChoice(){
    if(this.datedeb!=undefined && this.datefin!=undefined ){
      this.getAllPresences() ;
    }else
      console.log("Veuillez renseigner tous les champs") ;

  }

  getAllPresences(){

    var debut = formatDate(this.datedeb, "yyyy-MM-dd", 'en-US');
    let fin = formatDate(this.datefin, "yyyy-MM-dd", 'en-US');

    this.configServer.getAllPresences(debut, fin).then( (retourbi) => {
      this.retourClientWS = retourbi ;
      var statutrec = this.retourClientWS.statutreq;

      if(statutrec==1){
        console.log("Done") ;
        this.allProduction = this.retourClientWS.data ; 
        this.dataSource =  new MatTableDataSource(this.allProduction) ;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      else{
        console.log("Failed") ;
      }
    }) ;    
  }


  loadInfos(choosedCourse){
    this.courses = choosedCourse ;

    if(this.datedeb!=undefined && this.datefin!=undefined ){

      if(this.courses==1){
        this.listedchoice = "Présences" ;      
        this.getAllPresences();      
        this.peutavoirdescente=true ;
      }
      if(this.courses==2){
        this.listedchoice = "Retards" ;      
        this.getAllRetards();      
        this.peutavoirdescente=true ;
      }
      if(this.courses==3){
        this.listedchoice = "Absences" ;      
        this.getAllAbsences();      
        this.peutavoirdescente=false ;
      }

    }else
      console.log("Veuillez renseigner tous les champs") ;

  }

  getAllRetards(){
    var debut = formatDate(this.datedeb, "yyyy-MM-dd", 'en-US');
    var fin = formatDate(this.datefin, "yyyy-MM-dd", 'en-US');

    this.configServer.getAllRetards(debut, fin).then( (retourbi) => {
      this.retourClientWS = retourbi ;
      var statutrec = this.retourClientWS.statutreq;

      if(statutrec==1){
        console.log("Done") ;
        this.allProduction = this.retourClientWS.data ; 
        this.dataSource =  new MatTableDataSource(this.allProduction) ;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      else{
        console.log("Failed") ;
      }
    }) ;    

  }

  getAllAbsences(){
    var debut = formatDate(this.datedeb, "yyyy-MM-dd", 'en-US');
    var fin = formatDate(this.datefin, "yyyy-MM-dd", 'en-US');

    this.configServer.getAllAbsences(debut, fin).then( (retourbi) => {
      this.retourClientWS = retourbi ;
      var statutrec = this.retourClientWS.statutreq;

      if(statutrec==1){
        console.log("Done") ;
        this.allProduction = this.retourClientWS.data ; 
        this.dataSource =  new MatTableDataSource(this.allProduction) ;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      else{
        console.log("Failed") ;
      }
    }) ;    
    
  }

  getAllRunningDemands(){
    var debut = formatDate(this.datedeb, "yyyy-MM-dd", 'en-US');
    var fin = formatDate(this.datefin, "yyyy-MM-dd", 'en-US');

    this.configServer.getAllRunningDemands().then( (retourbi) => {
      this.retourClientWS = retourbi ;
      var statutrec = this.retourClientWS.status;

      if(statutrec==1){
        console.log("Done") ;
        this.allProduction = this.retourClientWS.courses ; 
        this.dataSource =  new MatTableDataSource(this.allProduction) ;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      else{
        console.log("Failed") ;
      }
    }) ;    
    
  }


  exportexcel(){
    var data = null ;
    const dialogRef = this.dialog.open(ExportationComponent, {data});
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });   
  }


  exportTable(): void
  {
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Feuil1');
 
   this.fileName = this.theFiltre+"-"+this.listedchoice+"-"+this.datedeb.toLocaleDateString()+"#"+this.datefin.toLocaleDateString()+".xlsx";

    XLSX.writeFile(wb, this.fileName);
 
  }


  getAllFinishedDemands(){
    var debut = formatDate(this.datedeb, "yyyy-MM-dd", 'en-US');
    var fin = formatDate(this.datefin, "yyyy-MM-dd", 'en-US');

    this.configServer.getAllFinishedDemands().then( (retourbi) => {
      this.retourClientWS = retourbi ;
      var statutrec = this.retourClientWS.status;

      if(statutrec==1){
        console.log("Done") ;
        this.allProduction = this.retourClientWS.courses ; 
        this.dataSource =  new MatTableDataSource(this.allProduction) ;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      else{
        console.log("Failed") ;
      }
    }) ;    
    
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.theFiltre = this.dataSource.filter ;
  }


  filtreheuredescente(ind){

  }


  goneAfterHour(theRow){
    if(theRow.hdescente.substr(11)<theRow.hdescenteplan)
      return false;
    else
      return true ;
  }

	openDialog(elt) {
    var data = {"elt": elt} ;

    console.log(data) ;

		const dialogRef = this.dialog.open(DetailcommandeComponent, {data});

		dialogRef.afterClosed().subscribe(result => {
		  console.log(`Dialog result: ${result}`);
		});
	}

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

}



@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'dialog-content-example-dialog.html',
  styleUrls: ['dialog-content-example-dialog.css']
})
export class DialogContentExampleDialog implements OnInit {

  detailsCommande = [] ;
	productionId : any  ;

	constructor(public dialog: MatDialog, private http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: any ) { 
    this.productionId = data.idprod ;
    console.log("The Production ID IS") ;
    console.log(this.productionId) ;
  }

	ngOnInit(): void {
		this.detailsCommande.push({"designation" : "ONDULEUR 1000_VA / 1000_VA", "qte" : 2, "pu" : 65000, "total" : 130000 , "infosup" : "Garantie 1 an" }) ;
		this.detailsCommande.push({"designation" : "TELEVISEUR HISENSE 32 SMART / 32B6700HA", "qte" : 1, "pu" : 160000 , "total" : 160000, "infosup" : "Garantie 6 mois"  }) ;
		this.detailsCommande.push({"designation" : "SAMSUNG GALAXY A01CORE / A013F", "qte" : 1, "pu" : 46000 , "total" : 46000 , "infosup" : "Garantie 2 an" }) ;
	}

}