import { Component, OnInit, Inject  } from '@angular/core';

import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';

import { ServicepoolService } from '../services/servicepool.service';
import { ReponseWebService } from '../datastruct/Meloyi.module';

@Component({
  selector: 'app-useroptions',
  templateUrl: './useroptions.component.html',
  styleUrls: ['./useroptions.component.css']
})
export class UseroptionsComponent implements OnInit {

  userinfos : any ;

  samacaabiyoonwii = "" ;

  retourClientWS  : ReponseWebService ;

  volet = "main" ;
  voletTampon = "" ;

  successOp = false ;

  failureOp = false ;


  activite : any ;
  dateLastConnexion : any ;
  actifDepuis : any ;
  inactifDepuis : any ;


  today : any;

  constructor(private configServer: ServicepoolService, public dialogRef: MatDialogRef<UseroptionsComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any ) { 
    this.userinfos = data.opt ;
    console.log(this.userinfos) ;
    this.today = this.getDateBi() ;
  }

  ngOnInit(): void {
      this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;
      this.getLastActivity() ;
  }


  annuler(){
  	this.dialogRef.close() ;
  }

  reinitPassWord(){

      this.voletTampon =this.volet ;
      this.volet = "loader" ;

    this.configServer.updateMotDePasseUser(this.samacaabiyoonwii, this.userinfos.iduser).then( (retourbi) => {
      this.retourClientWS = retourbi ;
      var statutreq = this.retourClientWS.statutreq;

      this.volet = this.voletTampon;
      console.log(retourbi) ;
      if(statutreq==1){
        console.log("Done Update Password !") ;
        this.successOp = true ;
      }
      else{
        console.log("Failed") ;
        this.failureOp = true ;
      }
    }) ;
  }


  desactiverCompte(){

      this.voletTampon =this.volet ;
      this.volet = "loader" ;

      this.configServer.desactiverCompte(this.samacaabiyoonwii, this.userinfos.iduser).then( (retourbi) => {
        this.retourClientWS = retourbi ;
        var statutreq = this.retourClientWS.statutreq;

        this.volet = this.voletTampon;
        console.log(retourbi) ;
        if(statutreq==1){
          console.log("Done Update Password !") ;
          this.successOp = true ;
        }
        else{
          console.log("Failed") ;
          this.failureOp = true ;
        }
      }) ;    
  }



  getDateBi(){
    let yourDate = new Date() ;
    yourDate.toISOString().split('T')[0] ;
    const offset = yourDate.getTimezoneOffset() ;
    yourDate = new Date(yourDate.getTime() - (offset*60*1000)) ;
    return yourDate.toISOString().split('T')[0] ;
  }


  getLastActivity(){

      this.voletTampon =this.volet ;
      this.volet = "loader" ;

      this.configServer.getLastActivity(this.samacaabiyoonwii, this.userinfos.iduser).then( (retourbi) => {
        this.retourClientWS = retourbi ;
        var statutreq = this.retourClientWS.statutreq;

        this.volet = this.voletTampon;
        console.log(retourbi.data) ;
        if(statutreq==1){
          console.log("Done Last Activity Agence !") ; 
          console.log( this.today) ;
          console.log( retourbi.data.session_day) ;

          let tayla = this.today==retourbi.data.session_day ? 1 : 0 ;

          console.log(tayla) ;
          
          this.activite  = Number(tayla)*Number(retourbi.data.session_state) ;

          this.dateLastConnexion = retourbi.data.session_start;
          this.actifDepuis = retourbi.data.session_start;
          this.inactifDepuis = retourbi.data.session_end;
        }
        else{
          console.log("Failed") ;
        }
      }) ;    
  }



}
