import { Component, OnInit, Inject, Input, Output, ViewChild, AfterViewInit} from '@angular/core';

import { HttpClient, HttpHeaders, HttpResponse, HttpEventType } from '@angular/common/http';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { formatDate } from "@angular/common";

import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';


import { DetailcommandeComponent } from '../detailcommande/detailcommande.component';
import {Observable} from 'rxjs';

import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import { ServicepoolService } from '../services/servicepool.service';

import { ReponseWebService } from '../datastruct/Meloyi.module';

import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

import { UsrcreationComponent } from '../usrcreation/usrcreation.component';

import { UserplanningComponent } from '../userplanning/userplanning.component';

import { FileuploaderComponent } from '../fileuploader/fileuploader.component';

import { saveAs } from 'file-saver';



@Component({
  selector: 'app-bulletinsalaire',
  templateUrl: './bulletinsalaire.component.html',
  styleUrls: ['./bulletinsalaire.component.css']
})
export class BulletinsalaireComponent implements OnInit {

	commands = [] ;

	allPartners : any ;

	datedeb : any ;
	datefin : any ;

	displayedColumns: string[] = ['dateload', 'service' ,'nompoint', 'action' ];
	dataSource : any ;

	userchoice = 1 ;

	samacaabiyoonwii = "" ;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	retourClientWS  : ReponseWebService ;

	constructor(public dialog: MatDialog,  private configServer: ServicepoolService, private _adapter: DateAdapter<any>, @Inject(MAT_DATE_LOCALE) private _locale: string) {
	    this._locale = 'fr';
	    this._adapter.setLocale(this._locale);
	    this.datedeb = new Date(); 
	    this.datefin = new Date(); 		
	}
	

	ngOnInit(): void {
	    this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;
	    this.getPeriodeSituation();
	}

	
    getProductionByDateInterval(){

	    var debut = formatDate(this.datedeb, "yyyy-MM-dd", 'en-US');
	    var fin = formatDate(this.datefin, "yyyy-MM-dd", 'en-US');

	    this.configServer.getGlobalProductionByDateInterval(1, debut, fin).then( (retourbi) => {
	      this.retourClientWS = retourbi ;
	      var statutrec = this.retourClientWS.statutreq ;

	      if(statutrec==1){
	        console.log("Done") ;
	        this.allPartners = this.retourClientWS.data ; 
	        this.dataSource =  new MatTableDataSource(this.allPartners) ;
	        this.dataSource.paginator = this.paginator;
		    this.dataSource.sort = this.sort;
	      }
	      else
	        console.log("Failed") ;
	    }) ;    
	}

	
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}


	loadInfos(inside){

		this.userchoice = inside ;
	}


  getPeriodeSituation(){

    var debut = formatDate(this.datedeb, "yyyy-MM-dd", 'en-US');
    var fin = formatDate(this.datefin, "yyyy-MM-dd", 'en-US');

    this.configServer.getPeriodeSituation(this.samacaabiyoonwii, debut, fin ).then( (retourbi) => {

      this.retourClientWS = retourbi ;
      var statutreq = this.retourClientWS.statutreq;
      console.log(this.retourClientWS) ;

      if(statutreq==1){
        console.log("Done") ;
        this.allPartners = this.retourClientWS.data ; 

        console.log(this.allPartners) ;

        this.dataSource =  new MatTableDataSource(this.allPartners) ;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      else{
        console.log("Failed") ;
      }
    }) ;    
  }


  getAllBulletins(){

    var debut = formatDate(this.datedeb, "yyyy-MM-dd", 'en-US');
    var fin = formatDate(this.datefin, "yyyy-MM-dd", 'en-US');

    this.configServer.getAllBulletins(debut, fin).then( (retourbi) => {
      this.retourClientWS = retourbi ;
      var statutreq = this.retourClientWS.statutreq;

      if(statutreq==1){
        console.log("Done") ;
        this.allPartners = this.retourClientWS.data ; 

        console.log(this.allPartners) ;

        this.dataSource =  new MatTableDataSource(this.allPartners) ;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      else{
        console.log("Failed") ;
      }
    }) ;    
  }


	openDialog(cmd): void {
		
		let passedValues = {'opt':cmd} ;

		const dialogRef = this.dialog.open(FileuploaderComponent, {
		  data: passedValues, disableClose:true
		});

		dialogRef.afterClosed().subscribe(result => {
		  console.log('The dialog was closed');
		});		
	}


	openDialogPar(elt): void {
		
		let passedValues = {'opt':elt} ;

		const dialogRef = this.dialog.open(UserplanningComponent, {
		  data: passedValues, disableClose:true
		});

		dialogRef.afterClosed().subscribe(result => {
		  console.log('The dialog was closed');
		});		
	}

	downloadExcel(element: any) {
		window.open("https://transferts.smkfinances.com/transferts/rprts/"+element.nomrapport, '_blank');
	}


}
