import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { formatDate } from "@angular/common";

import {MatTableDataSource} from '@angular/material/table';

import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';


import { ServicepoolService } from '../services/servicepool.service';

import { ReponseWebService } from '../datastruct/Meloyi.module';

import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

import { UsrcreationComponent } from '../usrcreation/usrcreation.component';

import { UserplanningComponent } from '../userplanning/userplanning.component';

import { UseroptionsComponent } from '../useroptions/useroptions.component';

import { PartneroptionsComponent } from '../partneroptions/partneroptions.component';

import { PointoptionsComponent } from '../pointoptions/pointoptions.component';


@Component({
  selector: 'app-partenaires',
  templateUrl: './partenaires.component.html',
  styleUrls: ['./partenaires.component.css']
})
export class PartenairesComponent implements OnInit {

	commands = [] ;

	allPartners : any ;

	datedeb : any ;
	datefin : any ;

	displayedColumnsPoint: string[] = ['nompoint', 'region', 'adresse',  'datecreat', 'details'];
	displayedColumns: string[] = ['prenom', 'nom', 'telephone', 'point', 'adresse', 'region', 'datecreat', 'details'];

	dataSource : MatTableDataSource<any> ;
	dataSourceUsers : MatTableDataSource<any> ;

	dataSourceActifPoints : MatTableDataSource<any> ;
	dataSourceInactifPoints : MatTableDataSource<any> ;

	dataSourceActifUsers : MatTableDataSource<any> ;
	dataSourceInactifUsers : MatTableDataSource<any> ;


	choosenGroup = "Points/Agences" ;


	nbreActifs = 0 ;
	nbreInactifs = 0 ;

	nbreActifUsers = 0 ;
	nbreInactifUsers = 0;
	
	userchoice = 1 ;

	samacaabiyoonwii = "" ;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	retourClientWS  : ReponseWebService ;

	constructor(public dialog: MatDialog,  private configServer: ServicepoolService, private _adapter: DateAdapter<any>, @Inject(MAT_DATE_LOCALE) private _locale: string) {
	    this._locale = 'fr';
	    this._adapter.setLocale(this._locale);
	    this.datedeb = new Date(); 
	    this.datefin = new Date(); 		
	}
	

	ngOnInit(): void {

	    this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;

		this.getAllCompanies() ;
	    this.getAllPartners();
	}

	
    getProductionByDateInterval(){

	    var debut = formatDate(this.datedeb, "yyyy-MM-dd", 'en-US');
	    var fin = formatDate(this.datefin, "yyyy-MM-dd", 'en-US');

	    this.configServer.getGlobalProductionByDateInterval(1, debut, fin).then( (retourbi) => {
	      this.retourClientWS = retourbi ;
	      var statutrec = this.retourClientWS.statutreq ;

	      if(statutrec==1){
	        console.log("Done") ;
	        this.allPartners = this.retourClientWS.data ; 
	        this.dataSource =  new MatTableDataSource(this.allPartners) ;
	        this.dataSource.paginator = this.paginator;
		    this.dataSource.sort = this.sort;
	      }
	      else
	        console.log("Failed") ;
	    }) ;    
	}

	
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
		this.dataSourceUsers.filter = filterValue.trim().toLowerCase();
	}


	loadInfos(inside){

		this.userchoice = inside ;

		if(inside==1){
			this.getAllCompanies();
			this.choosenGroup = "Points/Agences" ;
		}
		if(inside==2){
			this.getAllPartners();
			this.choosenGroup = "Caissiers" ;
		}
		if(inside==3){
			this.getAllActifsPoints();
			this.choosenGroup = "Points/Agences Actifs" ;
		}
		if(inside==4){
			this.getAllInactifsPoints();
			this.choosenGroup = "Points/Agences Inactifs" ;
		}
		if(inside==5){
			this.getAllActifsUsers();
			this.choosenGroup = "Caissiers Actifs" ;
		}
		if(inside==6){
			this.getAllInactifsUsers();
			this.choosenGroup = "Caissiers Inactifs" ;
		}

		this.dataSourceActifPoints.filter = "".trim().toLowerCase();
		this.dataSourceInactifPoints.filter = "".trim().toLowerCase();
		this.dataSource.filter = "".trim().toLowerCase();
		this.dataSourceUsers.filter = "".trim().toLowerCase();

	}



  getAllPartners(){

	this.getAllActifsUsers() ;
	this.getAllInactifsUsers() ;

    this.configServer.getAllPartners(this.samacaabiyoonwii).then( (retourbi) => {
      this.retourClientWS = retourbi ;
      var statutreq = this.retourClientWS.statutreq;

      if(statutreq==1){
        console.log("Done") ;
        this.allPartners = this.retourClientWS.data ; 

        console.log(this.allPartners) ;

        this.dataSourceUsers =  new MatTableDataSource(this.allPartners) ;
        this.dataSourceUsers.paginator = this.paginator;
        this.dataSourceUsers.sort = this.sort;
      }
      else{
        console.log("Failed") ;
      }
    }) ;    
  }


	getAllCompanies(){

		this.getAllActifsPoints() ;
		this.getAllInactifsPoints() ;

		this.configServer.getAllCompanies(this.samacaabiyoonwii).then( (retourbi) => {
		  this.retourClientWS = retourbi ;
		  var statutreq = this.retourClientWS.statutreq;

		  if(statutreq==1){
		    console.log("Done") ;
		    this.allPartners = this.retourClientWS.data ; 

		    console.log(this.allPartners) ;

		    this.dataSource =  new MatTableDataSource(this.allPartners) ;
		    this.dataSource.paginator = this.paginator;
		    this.dataSource.sort = this.sort;
		  }
		  else{
		    console.log("Failed") ;
		  }
		}) ;    
	}


	getAllActifsPoints(){
		this.configServer.getAllActifsPoints(this.samacaabiyoonwii).then( (retourbi) => {
		  this.retourClientWS = retourbi ;
		  var statutreq = this.retourClientWS.statutreq;

		  if(statutreq==1){
		    console.log("Done") ;
		    this.allPartners = this.retourClientWS.data ; 

			this.nbreActifs = this.allPartners.length ;

		    this.dataSourceActifPoints =  new MatTableDataSource(this.allPartners) ;
		    this.dataSourceActifPoints.paginator = this.paginator;
		    this.dataSourceActifPoints.sort = this.sort;
		  }
		  else{
		    console.log("Failed") ;
		  }
		}) ;
	}


	getAllInactifsPoints(){
		this.configServer.getAllInactifsPoints(this.samacaabiyoonwii).then( (retourbi) => {
		  this.retourClientWS = retourbi ;
		  var statutreq = this.retourClientWS.statutreq;

		  if(statutreq==1){
		    console.log("Done") ;
		    this.allPartners = this.retourClientWS.data ; 

			this.nbreInactifs = this.allPartners.length ;

		    this.dataSourceInactifPoints =  new MatTableDataSource(this.allPartners) ;
		    this.dataSourceInactifPoints.paginator = this.paginator;
		    this.dataSourceInactifPoints.sort = this.sort;
		  }
		  else{
		    console.log("Failed") ;
		  }
		}) ;    
	}

 

	getAllActifsUsers(){
		this.configServer.getAllActifsUsers(this.samacaabiyoonwii).then( (retourbi) => {
		  this.retourClientWS = retourbi ;
		  var statutreq = this.retourClientWS.statutreq;

		  if(statutreq==1){
		    console.log("Done") ;
		    this.allPartners = this.retourClientWS.data ; 

			this.nbreActifUsers = this.allPartners.length ;

		    this.dataSourceActifUsers =  new MatTableDataSource(this.allPartners) ;
		    this.dataSourceActifUsers.paginator = this.paginator;
		    this.dataSourceActifUsers.sort = this.sort;
		  }
		  else{
		    console.log("Failed") ;
		  }
		}) ;
	}


	getAllInactifsUsers(){
		this.configServer.getAllInactifsUsers(this.samacaabiyoonwii).then( (retourbi) => {
		  this.retourClientWS = retourbi ;
		  var statutreq = this.retourClientWS.statutreq;

		  if(statutreq==1){
		    console.log("Done") ;
		    this.allPartners = this.retourClientWS.data ; 

			this.nbreInactifUsers = this.allPartners.length ;

		    this.dataSourceInactifUsers =  new MatTableDataSource(this.allPartners) ;
		    this.dataSourceInactifUsers.paginator = this.paginator;
		    this.dataSourceInactifUsers.sort = this.sort;
		  }
		  else{
		    console.log("Failed") ;
		  }
		}) ;    
	}


	openDialog(cmd): void {		
		let passedValues = {'opt':cmd} ;
		const dialogRef = this.dialog.open(UsrcreationComponent, {
		  data: passedValues, disableClose:true
		});
		dialogRef.afterClosed().subscribe(result => {
		  console.log('The dialog was closed');
		});		
	}


	openDialogUser(elt): void {
		
		let passedValues = {'opt':elt} ;

		const dialogRef = this.dialog.open(UseroptionsComponent, {
		  data: passedValues, disableClose:true
		});

		dialogRef.afterClosed().subscribe(result => {
		  console.log('The dialog was closed');
		});		
	}

	openDialogPar(elt): void {
		
		let passedValues = {'opt':elt} ;

		const dialogRef = this.dialog.open(PartneroptionsComponent, {
		  data: passedValues, disableClose:true
		});

		dialogRef.afterClosed().subscribe(result => {
		  console.log('The dialog was closed');
		});		
	}


}


@Component({
  selector: 'partenairemodal',
  templateUrl: 'partenairemodal.html',
})
export class Partenairemodal {

  constructor(
    public dialogRef: MatDialogRef<Partenairemodal>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  	console.log(this.data.commande) ;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
